//react
import React, { useEffect, useState } from "react";
import JsxParser from "react-jsx-parser";
import { useTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { useHistory, Link } from "react-router-dom";
import AppSlice from "@store/app";

//custom hooks
import useGroupedCoursesList from "@hooks/useGroupedCoursesList";
import useGroupedPodcastsList from "@hooks/useGroupedPodcastsList";
import useTakeCourse from "@hooks/useTakeCourse";

//components 
import Loader from "@components/Loader";
import PageWrapper from "@components/PageWrapper";
import CoursesTable from '@components/CoursesTable/CoursesTable';
import CStyleBackground from "@components/CStyleBackground/CStyleBackground";

//pictures and icons
import { IoChevronBack } from "react-icons/io5";
import { IoChevronForward } from "react-icons/io5";
import "./style.css";

const Dashboard = (props) => {
    const { state } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const { takeCourse } = useTakeCourse();
    const { t, i18n } = useTranslation();
    const language = i18n.language;

    const [seeLevel, setSeeLevel] = useState('');
    const cStyleName = useSelector(state => {
        const currentResults = state.firestore.data?.myCstyle?.results?.cStyle?.cStyleName;
        const previousResults = state.app.lastCompletedCStyle?.results?.cStyle?.cStyleName;

        if (currentResults) return currentResults;
        else if (previousResults) return previousResults;
        else return '';
    })

    useEffect(() => {
        if (state?.showmodal) {
            dispatch(
                AppSlice.actions.setModal({
                    visible: true,
                    content: { type: "styleRequired" },
                })
            );
        }
    }, [state, dispatch]);

    useEffect(() => {
        dispatch(AppSlice.actions.setHeader({ visible: true, showHamburger: true }));
        dispatch(AppSlice.actions.setFooter(true));
    }, [dispatch]);

    const userId = useSelector((state) => state.firebase.auth.uid);
    const isLiteUser = useSelector(state => {
        if (typeof state.firestore.data.users === 'object') {
            return Object.entries(state.firestore.data.users)[0][1]?.liteUser;
        } else {
            return true;
        }
    });
    const isStylePending = useSelector((state) => state.app.isStylePending);
    const showWelcome = useSelector((state) => state.app.showWelcome);
    const cStyleTaken = useSelector(state => state.app.lastCompletedCStyle || false);
    const skipIntroModal = useSelector(state => state.app?.skipIntroModal);
    const userJoiningEvent = useSelector(state => {
        if (state.firestore?.data?.users && state.firestore?.data?.users[userId]) {
            return state.firestore?.data?.users[userId].joiningEvent
        } else {
            return '';
        }
    });

    const scienceEvents = useSelector(state => state.si.scienceEvents.events || []);

    useEffect(() => {
        if (showWelcome && !skipIntroModal) {
            dispatch(
                AppSlice.actions.setModal({
                    visible: true,
                    content: { type: "welcome" },
                })
            );
        }
    }, [showWelcome, skipIntroModal, dispatch]);

    useFirestoreConnect([
        { collection: "users", doc: userId },
        {
            collection: "badgeGroups/mainBadges/badgeItems",
            storeAs: "mainBadges"
        }
    ]);

    const user = useSelector((state) => state.firestore.data?.users?.[userId]);
    const levels = useSelector((state) => state.firestore.data?.mainBadges);

    useEffect(() => {
        if (user) {
            setSeeLevel(user.level);
        }
    }, [user])

    const handleIncreaseSeeLevel = () => {
        if (seeLevel < 5) {
            setSeeLevel(level => level + 1);
        }
    }

    const handleDecreaseSeeLevel = () => {
        if (seeLevel > 1) {
            setSeeLevel(level => level - 1);
        }
    }

    const courses = useGroupedCoursesList(userId);
    const podcasts = useGroupedPodcastsList(userId);

    const inProgressItems =
        courses && podcasts
            ? [
                ...courses.inProgress.map((c) => ({ ...c, type: "topic" })),
                ...podcasts.inProgress.map((c) => ({
                    ...c,
                    type: "podcast",
                })),
            ]
            : [];

    inProgressItems.sort((a, b) => a.timeStarted - b.timeStarted);

    return (
        <div className="dashboard">
            <PageWrapper>
                {isStylePending == null ? (
                    <Loader />
                ) : (
                    <>
                        <p className="subtitle">{t('resultsPages.bottomButtons.dash')}</p>
                        {
                            user.name
                                ? <h2>{t("dashboard.greeting")} {user?.name}!</h2>
                                : <h2>{t("dashboard.greeting")}!</h2>
                        }
                        {isStylePending ? (
                            <>
                                <p>
                                    {t("dashboard.mainHeaderCStylePending")}
                                </p>
                            </>
                        ) : (
                            <p className="larger">
                                {t("dashboard.mainHeader.firstPartText")}
                                <Link to="/courses">
                                    {t("dashboard.mainHeader.courseLink")}
                                </Link>
                                {t("dashboard.mainHeader.secondPartText")}
                                <Link to="/podcasts">
                                    {t("dashboard.mainHeader.podcastLink")}
                                </Link>
                                {t("dashboard.mainHeader.thirdPartText")}
                            </p>
                        )}

                        <div className="level-banner-section">
                            <h1>{t("dashboard.levelSection.title")}</h1>
                            <div className="user-level-container">
                                <div className="bg-container">
                                    <CStyleBackground />
                                </div>

                                <div className="text-container">
                                    <span
                                        style={seeLevel < 2 ? { 'color': 'transparent' } : null}
                                        className="noselect"
                                        onClick={handleDecreaseSeeLevel}
                                    >
                                        <IoChevronBack />
                                    </span>

                                    {seeLevel > user.level
                                        ? <h2 style={{ 'color': 'rgba(196, 196, 196, .7)' }}><b>{t("dashboard.levelSection.level")} {seeLevel}</b></h2>
                                        : seeLevel < user.level
                                            ? <h2><b>{t("dashboard.levelSection.level")} {seeLevel}</b></h2>
                                            : <h2>{t("dashboard.levelSection.currentLevel.firstPart")} <br /><b>{t("dashboard.levelSection.currentLevel.secondPart")} {seeLevel}</b></h2>
                                    }

                                    <span
                                        style={seeLevel > 4 ? { 'color': 'transparent' } : null}
                                        className="noselect"
                                        onClick={handleIncreaseSeeLevel}
                                    >
                                        <IoChevronForward />
                                    </span>
                                </div>
                            </div>
                            {levels &&
                                <JsxParser
                                    jsx={seeLevel > user?.level
                                        ? levels[`main${seeLevel}`]?.toDo[language] || ''
                                        : levels[`main${seeLevel}`]?.done[language] || ''}
                                />
                            }
                        </div>

                        {
                            !isLiteUser &&
                            <div className={`courses-section ${!cStyleTaken || !scienceEvents.includes(userJoiningEvent?.id) ? 'hide' : null}`}>
                                <h1>GLP-1 DIABETES BASICS</h1>
                                <CoursesTable
                                    showCourses={false}
                                    showScience={true}
                                />
                            </div>
                        }

                        {isLiteUser
                            ? <div className="tests-section">
                                <h1>{t("dashboard.resultsSection.title")}</h1>
                                {
                                    cStyleTaken
                                        ? <CoursesTable
                                            showDiagnostics={true}
                                            cStyleName={t(`resultsPages.styles.${cStyleName}`)}
                                            resultsText={t(`dashboard.resultsSection.seeResultsLink`)}
                                        />
                                        : <CoursesTable
                                            showDiagnostics={true}
                                            showOnlyMain={true}
                                        />
                                }
                            </div>
                            : <div className="tests-section">
                                <h1>{t("dashboard.resultsSection.title")}</h1>
                                {
                                    cStyleTaken
                                        ? <CoursesTable
                                            showDiagnostics={true}
                                            cStyleName={t(`resultsPages.styles.${cStyleName}`)}
                                            resultsText={t(`dashboard.resultsSection.seeResultsLink`)}
                                        />
                                        : <CoursesTable
                                            showDiagnostics={true}
                                            showOnlyMain={true}
                                        />
                                }
                            </div>
                        }


                        <div className={`courses-section ${!cStyleTaken ? 'hide' : null}`}>
                            <h1>{t("dashboard.coursesSection.title")}</h1>
                            <CoursesTable
                                showCourses={true}
                            />
                        </div>


                        <div className='bottom-buttons-section'>
                            <button
                                className="button-primary"
                                onClick={
                                    () => cStyleTaken ?
                                        history.push('/courses')
                                        : takeCourse(user?.mainCourse, 'take_course', 'Finding your C-Style')
                                }
                            >
                                {cStyleTaken ? t("dashboard.bottomButtonsSection.primary.cStyleTaken") : t("dashboard.bottomButtonsSection.primary.cStylePending")}
                            </button>
                            {/* <button
                                className={`button-secondary ${!cStyleTaken ? 'hide' : ''}`}
                                onClick={() => history.push('/podcasts')}
                            >
                                {t("dashboard.bottomButtonsSection.secondary")}
                            </button> */}
                        </div>

                    </>
                )}
            </PageWrapper>
        </div>
    );
};

export default Dashboard;
