import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import firebase from 'firebase';

// redux
import { initConference } from '@store/conference';
import { EVENTS, analyticsLogEvent } from '@components/Analytics';
import AppSlice from "@store/app";

// hooks
import useTakeCourse from '@hooks/useTakeCourse';

import PageSpinner from '@components/PageSpinner/PageSpinner';

const ConfLogin = () => {
  const { confId } = useParams();
  const dispatch = useDispatch();
  const { coursesInited, takeCourse } = useTakeCourse();
  const [startCourse, setStartCourse] = useState(false);
  const [snapshotExecuted, setSnapshotExecuted] = useState(false);
  const [currentShowText, setCurrentShowText] = useState(0);
  const conferenceName = useSelector(state => state.conference?.name);

  const generateUserNumber = firebase.functions().httpsCallable('user-getUserNumberInConference');

  const progressText = [
    'Creating account...',
    'Populate user courses...',
    'Nearly there...'
  ]

  useEffect(() => {
    if (coursesInited && startCourse && conferenceName) {
      setStartCourse(false);
      takeCourse('FcFUxrbSW8bg6RoX1n2g', 'go', 'Finding your c-style');
    }
  }, [coursesInited, startCourse, takeCourse, conferenceName]);

  useEffect(() => {
    (async () => {
      const db = firebase.firestore();

      await firebase.auth().signOut();
      const resp = await firebase.auth().signInAnonymously(); // Usually this takes 0.7 seconds. 
      const uid = resp.user.uid;

      const userCoursesQuery = await userListener(db, uid);
      if (userCoursesQuery && !snapshotExecuted) {
        setSnapshotExecuted(true);
        generateUserNumber({
          conferenceDocId: confId,
          userDocId: uid
        });

        db.collection('conference')
          .doc(confId)
          .collection('results')
          .doc(uid)
          .set({ exists: true })

        console.log('setting start course: true')
        analyticsLogEvent(EVENTS.user_sign_in_ev + confId);
        setStartCourse(true);
        initConference(dispatch, confId);
        console.log('end');
      }
    })()
  }, [confId])

  useEffect(() => {
    dispatch(
      AppSlice.actions.setHeader({ visible: true, showHamburger: false })
    );

    const startDate = new Date();

    return () => {
      const endDate = new Date();
      const loadedIn = endDate - startDate;
      console.log(`Conf login took ${loadedIn} to load`);
      analyticsLogEvent(
        confId +
        EVENTS.loading_time +
        Math.floor(loadedIn).toString()[0] + 's'
      )
    }
  }, [])

  useEffect(() => {
    if (currentShowText < 2)
      setTimeout(() => {
        setCurrentShowText(prev => prev + 1);
      }, 1500)
  }, [currentShowText])

  return (
    <PageSpinner loadingText={progressText[currentShowText]} />
  )
}

export default ConfLogin;

function userListener(db, uid) {
  return new Promise(async (resolve, reject) => {
    try {
      const userInterval = setInterval(async () => {
        const userCoursesQuerySnap = await db.collection('users').doc(uid).collection('userCourses').get();
        if (userCoursesQuerySnap.size > 0) {
          resolve(userCoursesQuerySnap)
          clearInterval(userInterval);
        }
      }, 100)
    } catch (err) {
      console.log(`Could not listen to user ${uid}. Error: ${err}`);
      reject();
    }
  })
}