import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Menu from "@components/Menu";
import { useSelector, useDispatch } from "react-redux";
import AppSlice from "@store/app";
import { ReactComponent as Logo } from "@images/logo.svg";
import { ReactComponent as CloseButton } from "@images/close-white.svg";
import { ReactComponent as MenuButton } from "@images/menu.svg";
import { ReactComponent as LogoFull } from "@images/logo-full.svg";
import { ReactComponent as Background } from "@images/background.svg";
import classnames from "classnames";
// import useGetCStyleResults from "@hooks/useGetCStyleResults"; TO BE DELETED
import { useTranslation } from "react-i18next";

import "./style.css";

const Header = (props) => {
    const { showHamburger } = props;
    const dispatch = useDispatch();
    const menuOpen = useSelector((state) => state.app.menuOpen);
    const userId = useSelector(state => state.firebase?.auth?.uid);
    const headerRef = useRef();

    const isStylePending = useSelector((state) => state.app.isStylePending);

    const myCStyle = useSelector(state => {
        const currentResults = state.firestore.data?.myCstyle?.results?.cStyle?.cStyleName;
        const previousResults = state.app.lastCompletedCStyle?.results?.cStyle?.cStyleName;

        if (currentResults) return currentResults;
        else if (previousResults) return previousResults;
        else return '';
    })

    const isLiteUser = useSelector(state => state?.firestore?.data?.users?.[userId]?.liteUser);

    const onToggleMenu = (action) => {
        dispatch(AppSlice.actions.setMenu(action));
    };

    // const [style, setStyle] = useState(false); TO BE DELETED
    const { t } = useTranslation();

    useEffect(() => {
        if (!menuOpen) {
            headerRef.current.classList.remove("expanded");
            document.body.classList.remove("no-scroll");
        } else {
            headerRef.current.classList.add("expanded");
            document.body.classList.add("no-scroll");
        }
    }, [menuOpen]);

    // const cStyleDefinition = useSelector( TO BE DELETED
    //     (state) =>
    //         state.app.mainCourse &&
    //         state.app.mainCourse.json &&
    //         JSON.parse(state.app.mainCourse.json)
    // );

    const desktopLinks = [
        {
            displayText: t("navMenu.dashboard"),
            text: "Dashboard",
            slug: "/",
        },
        {
            displayText: t("navMenu.courses"),
            text: "Courses",
            slug: "/courses",
        },
        {
            displayText: t("navMenu.podcasts"),
            text: "Podcasts",
            slug: "/podcasts",
        }
    ];

    const onHandleLink = (e, link) => {
        switch (link) {
            case "Courses":
            case "Podcasts":
                if (isStylePending) {
                    e.preventDefault();
                    dispatch(
                        AppSlice.actions.setModal({
                            visible: true,
                            content: { type: "styleRequired" },
                        })
                    );
                }
                break;
            default:
        }
    };

    return (
        <>
            <header>
                <div className="header" ref={headerRef}>
                    <div
                        className={classnames(
                            "header__background",
                            myCStyle && `bg-${myCStyle.toLowerCase()}`
                        )}
                    >
                        <Background />
                    </div>
                    <div className="header__heading">
                        <h1>
                            <Link to="/" onClick={() => onToggleMenu(false)}>
                                {menuOpen ? (
                                    <LogoFull className="logo-container__full" />
                                ) : (
                                    <Logo />
                                )}
                            </Link>
                        </h1>

                        {showHamburger && (
                            <>
                                <div className="header__heading__links">
                                    {desktopLinks.map((link, i) => (
                                        <Link
                                            key={i}
                                            to={link.slug}
                                            onClick={(e) =>
                                                onHandleLink(e, link.text)
                                            }
                                        >
                                            {link.displayText}
                                        </Link>
                                    ))}
                                </div>

                                <div
                                    className="header__heading__open-button"
                                    onClick={() => onToggleMenu(!menuOpen)}
                                >
                                    {menuOpen ? (
                                        <CloseButton />
                                    ) : (
                                        <MenuButton />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <Menu onClose={() => onToggleMenu(!menuOpen)} />
                </div>
            </header>
            <div className="fake-header" />
        </>
    );
};

export default Header;
